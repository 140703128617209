import { create } from "Utils/helpers/CreateStateHandler";
import * as Handlers from './actionsHandler';

export const Actions = {
	Add: 'add',
	Remove: 'Remove',
	Clear: 'Clear',
};

const initialState = {
	itemCount: 0,
	items: {},
};

const reducer = (state, action) => {
	let { type, payload } = action;
	switch (type) {
		case Actions.Add:
			return Handlers.AddItem({ state, payload });
		case Actions.Remove:
			return Handlers.RemoveItem({ state, payload });
		default:
			return state;
	}
};

export const { Provider, UseContext } = create({
	initialState,
	reducer,
});
