import { create } from "Utils/helpers/CreateStateHandler";

export const Actions = {
	ThemeLightMode: 'theme-light-mode',
	ThemeDarkMode: 'theme-dark-mode',
}

const initialState = {
	darkMode: false,
};

const reducer = (state, action) => {
	let { type } = action;
	switch (type) {
		case Actions.ThemeLightMode:
			return {
				...state,
				darkMode: false
			};
		case Actions.ThemeDarkMode:
			return {
				...state,
				darkMode: true
			};
		default:
			return state;
	}
};

export const { Provider, UseContext } = create({
	initialState,
	reducer,
});
