

const ENV = process.env;

/**
 * @global {Object} BUDDI
 */
var BUDDI = {
	config: {
		debug: (ENV.REACT_APP_DEBUG === 'true'),
		env: ENV.NODE_ENV,
		basename: ENV.REACT_APP_BASENAME || '/',
		apiUrl: ENV.REACT_APP_API_URL,
	},
	lib: {},
	services: {}
}

/**
 * Global Logger
 * @param  {...any} args
 * @global {Function} logger
 */
var logger = function (...args) {
	if (BUDDI.config.debug) {
		console.log(...args)
	}
}

// var cvNavigate = (href) => {
//    window.history.pushState({}, '', href);
//    // communicate to Routes that URL has changed
//    const navEvent = new PopStateEvent('popstate');
//    window.dispatchEvent(navEvent);
// }

// window['BUDDI'] = BUDDI;
global['BUDDI'] = BUDDI;
global['logger'] = logger;
