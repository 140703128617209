
/**
 *
 * @param {Object} itemList
 * @returns {String[]}
 */
function ItemList(itemList) {
	return Object.keys(itemList);
}

function SearchList(itemList, item) {
	let //keys = ItemList(itemList.items),
		itemId = item.id,
		isExist = (itemId in itemList.items);

	return isExist ? itemList.items[itemId] : null;
}

function CreateItemNode(itemList, item) {
	const newItem = {
		count: 1,
		data: item,
	},
		items = {
			...itemList.items,
			[item.id]: newItem,
		};

	return {
		itemCount: ItemList(items).length,
		items,
	}
}

export const AddItem = (opt) => {
	let { state, payload: { item } } = opt;

	let itemFound = SearchList(state, item);

	if (!itemFound) {
		return CreateItemNode(state, item);
	} else {
		itemFound.count++;
		return {
			itemCount: state.itemCount + 1,
			items: state.items,
		}
	}
}

export const RemoveItem = (opt) => {
	// let { state, payload } = opt;

}
