import { createContext, useContext, useReducer } from "react";

export function create(opt) {
	const Context = createContext();
	const { initialState, reducer } = opt

	return {
		Provider: (props) => {
			const [state, dispatch] = useReducer(reducer, initialState);

			return (
				<Context.Provider value={{ state, dispatch }}>
					{props.children}
				</Context.Provider>
			)
		},
		UseContext: () => {
			let { state = null, dispatch = null } = useContext(Context) || {};
			return { state, dispatch }
		},
	}
}
