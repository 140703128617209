import { Suspense } from 'react';
import { Provider as ThemeProvider } from 'providers/Theme';
import { Provider as ShoppingCartProvider } from 'providers/ShoppingCart';
import {
	BrowserRouter,
	Routes,
	Route,
	// NavLink,
	// Navigate,
	// useNavigate,
} from 'react-router-dom';

import InternalLinks from 'enums/InternalLinks';
import Pages from 'pages';
import Loading from 'components/Loading';

import './App.css';

// eslint-disable-next-line import/no-anonymous-default-export
// https://reactrouter.com/docs/en/v6/getting-started/overview
export default () => {
	return (
		<ThemeProvider>
			<ShoppingCartProvider>
				<Suspense fallback={<Loading />}>
					<BrowserRouter basename={'/online-menu'}>
						<Routes>
							<Route index element={<Pages.Home />} />
							<Route path={InternalLinks.ROOT} element={<Pages.Home />} />

							<Route path={'*'} element={<Pages.NotFound />} />
						</Routes>
					</BrowserRouter>
				</Suspense>
			</ShoppingCartProvider>
		</ThemeProvider>
	)
};
