import Axios from 'axios';

/**
 *
 * @param {Object} opt
 * @param {String} [opt.method]
 * @param {String} [opt.url] Base url.
 * @param {String} opt.path request path.
 * @param {Object} [opt.token] Bendigi API token.
 * @param {Object} [opt.headers] Http headers
 * @param {Object} [opt.data] Post data
 * @param {Object} [opt.params] Query Params
 * @returns {Promise<Object|null>}
 */
async function apiCall(opt) {
	let isGetRequest = opt.method === 'GET',
		headers = {
			'Cache-Control': 'no-cache',
			'Pragma': 'no-cache',
			'Expires': '0',
			...opt.headers,
		},
		config = {
			timeout: 10000,
			method: opt.method || 'GET',
			baseURL: opt.url,
			url: opt.path,
			headers,
			// httpsAgent: new https.Agent({
			// 	rejectUnauthorized: false
			// }),
			...opt.data ? { data: isGetRequest ? JSON.stringify(opt.data) : opt.data } : null,
		},
		result = null;

	if (opt.params) config.params = opt.params;

	try {
		logger(`ApiCAll [${config.method}]: ${config.url}`, config.data || {});
		// @ts-ignore
		result = await Axios(config).then(response => response.data);
	} catch (error) {
		let { status = null, data = null } = error.response || {},
			message = status ? data.error.message : `Failed Connecting to API Service @ ${config.baseURL}`;

		logger('ApiCAll Error:', message);
		throw new Error(message);
	}

	logger('ApiCAll Result:', result);

	return (result && result.success) ? result.result : null;
}


export default class ApiService {

	constructor() {
		this._accessToken = null
		this._accessType = null
	}

	get accessToken() { return this._accessToken; }
	set accessToken(val) { this._accessToken = val; }
	get accessType() { return this._accessType; }
	set accessType(val) { this._accessType = val; }

	toQueryString(params) {
		let tmpObj = {};
		Object.keys(params).forEach(key => {
			const val = params[key],
				isNull = val === undefined || val === null;
			if (!isNull) tmpObj[key] = val;
		})
		return new URLSearchParams(tmpObj).toString();
	}

	async request({ method, path, data, params }, isJson = true) {
		const API_BASE = BUDDI.config.apiUrl;

		let headers = (this.accessToken && this.accessType) ? {
			...isJson ? {
				'Content-Type': 'application/json',
			} : null,
			'Authorization': `${this.accessType} ${this.accessToken}`
		} : null;
		// uploadData = data ? (isJson ? JSON.stringify(data) : data) : null;

		return await apiCall({
			method,
			url: API_BASE,
			headers,
			path,
			data,
			params,
		});
	}
}
